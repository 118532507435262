@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.falcomap-15 .marker {
  transform: scale(0.8);
}
.falcomap-16 .marker {
  transform: scale(0.9);
}
.falcomap-17 .marker {
   transform: scale(1);
}
.falcomap-18 .marker {
  transform: scale(1.5);
}
.falcomap-19 .marker {
  transform: scale(2.5);
}
.falcomap-20 .marker {
  transform: scale(4);
}
.falcomap-21 .marker {
  transform: scale(5);
}
.falcomap-22 .marker {
  transform: scale(3);
}
.marker {
  transition:transform 0.3s;
}
/* not authorized */
/* .mapboxgl-ctrl-logo {
  display: none !important;
} */
body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "sans-serif";
  background: #f4f9fe;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiDrawer-paper::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
.MuiDrawer-paper::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.MuiDrawer-paper::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #eaeaea;
  /*outline: 1px solid slategrey;*/
}

.MuiDrawer-paper-h::-webkit-scrollbar {
  height: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
}
.MuiDrawer-paper-h::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
.MuiDrawer-paper-h::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #eaeaea;
  /*outline: 1px solid slategrey;*/
}
.drawer-menu-active .MuiListItemButton-root,
.drawer-menu-active .MuiListItemButton-root:hover {
  background-color: #3e5e93;
  cursor: pointer;
}
